import React, { Component } from 'react'
import './fields.css'

class FieldElem extends Component {

    getField() {
        if (!this.props.field || !this.props.field.type || !this.props.field.subtype || this.props.field.type !== 'text') return null
        const style = this.props.field.style ? this.props.field.style : {}
        switch (this.props.field.subtype) {
            case "heading":
                return <div className="Text-Heading" style={ style }>{ this.props.field.label }</div>
            case "title":
                return <div className="Text-Heading" style={ style }>{ this.props.field.label }</div>
            case "text":
                return <div style={ style }>{ this.props.field.label }</div>
            default:
                return null
        }
    }

    render() {
        return (
            <div className="Text-Container">{ this.getField() }</div> 
        )
    }
}

export default FieldElem