import React, { Component } from 'react'
import './fields.css'

class FieldTblTA extends Component {

    handleChange(val) {
        this.props.onValueChange(this.props.row, this.props.cell, val)
    }

    render() {
        const style = { width: "100%" }
        return (
            <textarea style={ style } rows={this.props.field.rows || null} cols={this.props.field.cols || null} onChange={ event => this.handleChange(event.target.value) } value={this.props.field.answer ? this.props.field.answer : "" } placeholder={this.props.field.placeholder ? this.props.field.placeholder : null} />
        )
    }
}

export default FieldTblTA