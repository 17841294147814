import React, { Component } from 'react'
import './fields.css'

class FieldCB extends Component {

    handleChange(val) {
        this.props.onValueChange(this.props.field, val.checked)
    }

    render() {
        let style = this.props.field.style ? this.props.field.style : null
        return (
            <div className="CB-PDF-Container">
                <input name={this.props.field.val} className="CB-PDF-Input" type="checkbox" onChange={ event => this.handleChange(event.target) } checked={this.props.field.answer || false} />
                <span className="CB-PDF-Label" style={ style }>
                    { this.props.field.label }
                </span>
            </div>
        )
    }
}

export default FieldCB