import React, { Component } from 'react'
import PrettyTbl from '../fields/prettyTbl'
import PrettyTB from '../fields/prettyTb'
import PrettyCB from '../fields/prettyCb'
import PrettyMulti from '../fields/prettyMulti'
import PrettyCBG from '../fields/prettyCbg'
import PrettyCBGRB from '../fields/prettyCbgrb'
import PrettySign from '../fields/prettySign'
import SignatureCanvas from 'react-signature-canvas'
import PrettySignPreview from '../utilities/prettySignPreview'
import FieldHTML from '../fields/fieldHtml'
import FieldText from '../fields/fieldText'
import Stepper from 'react-stepper-horizontal'
import './form.css'
import '../utilities/signature.css'

class PrettyForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sign: null,
            showSignature: false,
            css: '',
            scss: 'Pretty-Signature-Start'
        }
        this.sigPad = null
        this.handleValueChange = this.handleValueChange.bind(this)
        this.handleSignChanged = this.handleSignChanged.bind(this)
    }

    handleValueChange(field, val, idx, cell) {
        if (field.type === 'signature' || field.type === 'initial') {
            if (val) this.props.onFormChange({ ...field, answer: this.state.sign })
        } else if (field.type === 'table') {
            let newVal = Object.assign(field.rows)
            newVal[idx].cols[cell].answer = val
            this.props.onFormChange({ ...field, rows: newVal })
        } else if (field.type === 'cbg') {
            let newVal = Object.assign(field.options)
            newVal[idx].answer = val
            this.props.onFormChange({ ...field, options: newVal })
        } else if (typeof (idx) !== "undefined") {
            let newVal = Object.assign(field.answer || [])
            newVal[idx] = val
            this.props.onFormChange({ ...field, answer: newVal })
        } else {
            this.props.onFormChange({ ...field, answer: val })
        }
    }

    handleSignChanged(sign) {
        this.setState({ sign })
    }

    handleStartSignature() {
        this.setState({ css: ' Signature-Active' })
    }

    handleSignature() {
        this.setState({ sign: this.sigPad.getTrimmedCanvas().toDataURL('image/png'), css: '' })
    }

    handleSignatureDone() {
        this.setState({ scss: 'Pretty-Signature-Start' })
        setTimeout(() => this.setState({ showSignature: false }), 250)
    }

    handleNewSignature() {
        if (this.sigPad) this.sigPad.clear()
        this.setState({ sign: null, showSignature: true })
        setTimeout(() => this.setState({ scss: 'Pretty-Signature-End' }), 50)
    }

    handleButton(button) {
        this._div.scrollTop = 0
        let good = true
        if (button === "next") {
            this.props.form.forEach(f => { if (f.required && !f.answer) good = false })
        }
        if (good) {
            this.props.sectionButtonClicked(button)
        } else {
            alert('Missing Required Fields')
        }

    }

    getField(f) {
        switch (f.type) {
            case "cb":
                return <PrettyCB field={f} onValueChange={this.handleValueChange} />
            case "cbg":
                return <PrettyCBG field={f} onValueChange={this.handleValueChange} />
            case "cbgrb":
                return <PrettyCBGRB field={f} onValueChange={this.handleValueChange} />
            case "html":
                return <FieldHTML field={f} />
            case "initial":
                return <PrettySign field={f} onValueChange={this.handleValueChange} />
            case "multi":
                return <PrettyMulti field={f} onValueChange={this.handleValueChange} />
            case "signature":
                return <PrettySign field={f} onValueChange={this.handleValueChange} />
            case 'table':
                return <PrettyTbl field={f} onValueChange={this.handleValueChange} />
            case "tb":
                return <PrettyTB field={f} onValueChange={this.handleValueChange} />
            case "text":
                return <div style={{ fontWeight: 'bold' }}><FieldText field={f} /></div>
            default:
                return null
        }
    }

    getSection() {
        return (
            this.props.form.map((f, fidx) => {
                return (
                    <div key={`pretty-${fidx}`} className='Pretty-Row-Container'>
                        {this.getField(f)}
                    </div>
                )
            }))
    }

    render() {
        console.log('pretty form    ', this.props)
        return (
            <div className="Pretty-Container">
                <div className="Pretty-Header">
                    <div className="Pretty-Header-Label">
                        {this.props.title}
                    </div>
                    <div className="Tracker-Container">
                        <Stepper
                            steps={this.props.sections}
                            activeStep={this.props.section}
                            completeColor="#008800" />
                    </div>
                </div>
                <div ref={(ref) => this._div = ref} className="Pretty-Form-Container">
                    {this.getSection()}
                </div>
                <div className="Pretty-Footer">
                    <div className="Pretty-Button Pretty-Back Pretty-Left" onClick={() => this.handleButton('back')}>Back</div>
                    <div className="Pretty-Button Pretty-Quit Pretty-Left" onClick={() => this.handleButton('quit')}>Quit</div>
                    <div className="Pretty-Button Pretty-Next Pretty-Right" onClick={() => this.handleButton('next')}>{this.props.section === this.props.sections.length - 1 || this.props.sections.length === 0 ? 'PDF View' : 'Next'}</div>
                    <PrettySignPreview signature={this.state.sign} msg={this.state.sign ? 'Touch to Reset' : ''} onSign={() => this.handleNewSignature()} />
                </div>
                {
                    this.state.showSignature ?
                        <div className={`Pretty-Signature ${this.state.scss}`}>
                            <SignatureCanvas penColor={`#000`}
                                canvasProps={{ width: 800, height: 350, className: `Signature${this.state.css}` }}
                                ref={ref => { this.sigPad = ref }} onBegin={() => this.handleStartSignature()} onEnd={() => this.handleSignature()} />
                            <div className="Pretty-Signature-Button" onClick={() => this.handleSignatureDone()}>Accept Signature</div>
                        </div> : null
                }
            </div>
        )
    }
}

export default PrettyForm