import React, { Component } from 'react'
import FieldTblTB from './fieldTblTb'
import FieldTblTA from './fieldTblTa'
import './fields.css'

class FieldTbl extends Component {
    constructor(props) {
        super(props)

        this.handleValueChange = this.handleValueChange.bind(this)
    }

    handleValueChange(row, col, val) {
        this.props.onValueChange(this.props.field, val, row, col)
    }

    getRow(r) {
        return (
            r.cols.map((c, cid) => {
                return (
                    <div key={`col-${cid}`} className="Table-Cell" style={{ width: `${Math.floor(100/r.cols.length)}%`}}>
                        { c.type === 'heading' ? 
                            <div className="Table-Cell-Heading">{ c.label }</div>  : 
                            <div className="Table-Cell-TB">
                                { c.type === 'ta' ? 
                                    <FieldTblTA field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} /> :
                                    <FieldTblTB field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} />
                                }
                            </div>
                        }
                    </div>
                )
            })
        )
    }

    render() {
        return (
            this.props.field.rows.map((r, rid) => {
                return (
                    <div key={`row-${rid}`} className="Table-Row">
                        { this.getRow(r) }
                    </div>
                )
            })
        )
    }
}

export default FieldTbl
