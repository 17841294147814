import React, { Component } from 'react'
import './fields.css'

class FieldCBG extends Component {

    handleChange(val, idx) {
        this.props.onValueChange(this.props.field, val.checked, idx)
    }

    getOptions() {
        return (
            this.props.field.options.map((o, idx) => {
                return (
                    <div key={`cb-${idx}`} className="CB-PDF-Container">
                        <input name={o.val} className="CB-PDF-Input" type="checkbox" onChange={event => this.handleChange(event.target, idx)} checked={o.answer || false} />
                        <span className="CB-PDF-Label">
                            {o.label}
                        </span>
                    </div>
                )
            })
        )
    }

    render() {
        let style = this.props.field.style ? this.props.field.style : null
        return (
            <div className="CBG-Container">
                {this.props.field.label ? <div className="CB-PDF-Main-Label" style={style}>{this.props.field.label}</div> : null}
                {this.getOptions()}
            </div>
        )
    }
}

export default FieldCBG