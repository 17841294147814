import React, { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import MemberTable from '../components/memberTable'
import MemberCard from '../components/memberCard'
import './dashboard.css'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            tid: null,
            selected: null,
            ts: null,
            cardMenu: false,
            sort: null,
            updating: [],
            expand: [],
            fltr: 'all'
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleExpand = this.handleExpand.bind(this)
        this.handleUnSelect = this.handleUnSelect.bind(this)
        this.handleClearAlert = this.handleClearAlert.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
    }

    componentWillMount() {
        if (!this.props.user) {
            this.props.history.push('/')
            return false
        }
        API.get('rpm', '/rpm').then(data => {
            if (data && data.mbrs && data.measures) {
                data.mbrs.sort((a, b) => {
                    let x = a.bpAlert || a.w1dAlert || a.w5dAlert
                    let y = b.bpAlert || b.w1dAlert || b.w5dAlert
                    if (x && !y) return -1
                    if (!x && y) return 1
                    return 0
                })//.forEach(mbr => mbr.expand = false)
                const ts = data.measures.sort((a, b) => {
                    let x = a.ts
                    let y = b.ts
                    if (x > y) return -1
                    if (x < y) return 1
                    return 0
                })
                data.measures = data.measures.filter(m => {
                    if (m.values && m.values.weight) {
                        return data.measures.filter(z => {
                            return moment(z.ts).format('l') === moment(m.ts).format('l') && m.imei === z.imei
                        }).sort((a, b) => {
                            let x = a.ts
                            let y = b.ts
                            if (x > y) return 1
                            if (x < y) return -1
                            return 0
                        })[0].ts === m.ts
                    }
                    return m.values
                })
                this.setState({ data, ts: ts[0] ? ts[0].ts : null, expand: [] })
            }
        })
    }

    componentDidMount() {
        if (!this.props.user) {
            this.props.history.push('/')
            return false
        }
        const tid = setInterval(() => {
            API.get('rpm', `/rpm${this.state.ts ? '?ts=' + this.state.ts : ''}`).then(data => {
                if (data && data.mbrs && data.measures) {
                    const ts = data.measures.sort((a, b) => {
                        let x = a.ts
                        let y = b.ts
                        if (x > y) return -1
                        if (x < y) return 1
                        return 0
                    })
                    data.mbrs.sort((a, b) => {
                        let x = a.bpAlert || a.w1dAlert || a.w5dAlert
                        let y = b.bpAlert || b.w1dAlert || b.w5dAlert
                        if (x && !y) return -1
                        if (!x && y) return 1
                        return 0
                    })
                    let tmpMeasures = JSON.parse(JSON.stringify(this.state.data.measures))
                    data.measures.forEach(measure => {
                        if (measure.values.weight) {
                            if(tmpMeasures.filter(z => z.imei === measure.imei && moment(z.ts).format('l') === moment(measure.ts).format('l')).length === 0) tmpMeasures.push(measure)
                        } else {
                            tmpMeasures.push(measure)
                        }                        
                    })
                    
                    data.measures = tmpMeasures
                    this.setState({ data, ts: ts[0] ? ts[0].ts : this.state.ts, updating: [] })
                }
            })
        }, 5000)
        this.setState({tid: tid})
    }

    componentWillUnmount() {
        clearInterval(this.state.tid)
    }

    handleExpand(hccid) {
        let expand = this.state.expand.slice()
        if(expand.indexOf(hccid) < 0) {
            expand.push(hccid)
        } else {
            expand.splice(expand.indexOf(hccid), 1)
        }
        this.setState({expand})
    }

    handleSelect(selected) {
        this.setState({ selected })
    }

    handleUnSelect() {
        this.setState({ selected: null })
    }

    handleCardMenuSelected(cardMenu) {
        this.setState({ cardMenu })
    }

    handleClearAlert(alert) {
        let updating = this.state.updating.slice()
        updating.push(alert)
        this.setState({ updating })
        try {
            if (this.state.selected) {
                API.get('rpm', `/alert/${this.state.selected}?alert=${alert}`)
            }
        } catch (e) {
            alert(e.message)
        }
    }

    handleFilter(fltr) {
        this.setState({fltr})
    }

    getW5dMeasurement(ws) {
        const meas = ws.filter(w => moment(w.ts).format('l') === moment(new Date()).subtract(5, 'days').format('l'))
        if (meas.length === 0) return null
        const curr = meas.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })
        return curr[0]
    }

    render() {
        console.log('Dashboard props, state ===>     ', this.props, this.state)
        const mbr = this.state.selected && this.state.data && this.state.data.mbrs && this.state.data.mbrs.filter(m => m.hccid === this.state.selected)
        const ws = mbr && mbr[0] ? this.state.data.measures.filter(device => device.imei === mbr[0].scaleId).sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }) : null
        const w5d = ws ? this.getW5dMeasurement(ws) : null
        
        return (
            <div className="Dashboard-Container">
                {this.state.selected ?
                    <MemberCard onReturn={this.handleUnSelect} alertCleard={this.handleClearAlert}
                        mbr={mbr && mbr[0] ? mbr[0] : null}
                        bps={mbr && mbr[0] ? this.state.data.measures.filter(device => device.imei === mbr[0].bpmId).sort((a, b) => {
                            let x = a.ts
                            let y = b.ts
                            if (x > y) return -1
                            if (x < y) return 1
                            return 0
                        }) : null}
                        ws={ws}
                        w5d={w5d}
                        updating={this.state.updating}
                    /> :
                    <MemberTable data={this.state.data} onSelected={this.handleSelect} onExpanded={this.handleExpand} expanded={this.state.expand} filter={this.state.fltr} onFilter={ this.handleFilter } />}
            </div>
        )
    }
}

export default Dashboard