import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './fields.css'

class FieldHTML extends Component {

    render() {
        return (
            <div className="HTML-Container">
                { ReactHtmlParser(this.props.field.val) }
            </div>
        )
    }
}

export default FieldHTML