import React, { Component } from 'react'
import './signature.css'

class PrettySignPreview extends Component {
    constructor(props) {
        super(props)

        this.state= {
            msg: 'Touch to Sign'
        }
    }
    handleSignClick() {
        this.setState({ msg: 'Re-sign'})
        this.props.onSign()
    }
    render() {
        return (
            <div className="Signature-Preview-Container">
                    <span className="Signature-Label">{ this.props.msg }</span>
                    { this.props.signature ? 
                        <img className="Signature-Image" src={this.props.signature} alt="Signature" onClick={() => this.handleSignClick() } /> :
                        <div className="Signature-Image Signature-Instructions" onClick={() => this.handleSignClick() }>{ this.state.msg }</div>
                    }
            </div>
        )
    }
}

export default PrettySignPreview