import React, { Component } from 'react'
import FieldTblTB from './fieldTblTb'
import FieldTblTA from './fieldTblTa'
import { Table } from 'react-bootstrap'
import './fields.css'

class PrettyTbl extends Component {
    constructor(props) {
        super(props)

        this.handleValueChange = this.handleValueChange.bind(this)
    }

    handleValueChange(row, col, val) {
        this.props.onValueChange(this.props.field, val, row, col)
    }

    getRow(r) {
        return (
            r.cols.map((c, cid) => {
                return (
                    <div key={`col-${cid}`} className="Table-Cell" style={{ width: `${Math.floor(100/r.cols.length)}%`}}>
                        { c.type === 'heading' ? 
                            <div className="Table-Cell-Heading">{ c.label }</div>  : 
                            <div className="Table-Cell-TB">
                                { c.type === 'ta' ? 
                                    <FieldTblTA field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} /> :
                                    <FieldTblTB field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} />
                                }
                            </div>
                        }
                    </div>
                )
            })
        )
    }

    getHeadCells(r) {
        return (
            r.cols.map((c, cid) => {
                return (
                    <th key={`col-${cid}`}>{ c.label }</th>
                )
            })
        )
    }

    getBodyCells(r) {
        return (
            r.cols.map((c, cid) => {
                return (
                    <td key={`col-${cid}`}>
                        { c.type === 'ta' ? 
                            <FieldTblTA field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} /> :
                            <FieldTblTB field={c} row={r.row} cell={cid} onValueChange={this.handleValueChange} />
                        }
                    </td>
                )
            })
        )
    }

    render() {
        return (
            <Table responsive>
                <thead>
                    {
                        this.props.field.rows.filter(h => h.cols[0].type === 'heading').map((r, rid) => {
                            return (
                                <tr key={`row-${rid}`}>
                                    { this.getHeadCells(r) }
                                </tr>
                            )
                        })
                    }
                </thead>
                <tbody>
                    {
                        this.props.field.rows.filter(h => h.cols[0].type !== 'heading').map((r, rid) => {
                            return (
                                <tr key={`row-${rid}`}>
                                    { this.getBodyCells(r) }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

export default PrettyTbl
