import React, { Component } from 'react'
import { API } from 'aws-amplify'
import PDFForm from '../components/screens/pdfForm'
import PrettyForm from '../components/screens/prettyForm'
import { registration as Registration } from '../helpers/reg.json'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { s3Upload } from '../libs'
import './register.css'

class Register extends Component {
    constructor(props) {
        super(props)

        this.state = {
            regform: null,
            form: 0,
            formPage: 0,
            section: 0,
            view: 'pretty',
            sections: [],
            prettyForm: [],
            isSaving: false,
            pdf: []
        }

        this.handleToPDF = this.handleToPDF.bind(this)
        this.handlePDFFormChange = this.handlePDFFormChange.bind(this)
        this.handlePrettyFormChange = this.handlePrettyFormChange.bind(this)
        this.handlePrettyStepButton = this.handlePrettyStepButton.bind(this)
    }

    componentWillMount() {
        if (!this.props.user) {
            this.props.history.push('/')
            return false
        }
        this.setState({ regform: Registration })
    }

    componentDidMount() {
        if (!this.props.user) {
            this.props.history.push('/')
            return false
        }
        this.handleFormChange()
    }

    isIOS() {
        let isIos = /ipad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream
        return isIos
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.isSaving && (prevState.formPage !== this.state.page || !prevState.isSaving)) {
            if (!this.isIOS()) {
                this.handleSavePDF(this.state.formPage)
            } else {
                this.setState({ pdf: null, isSaving: false })
            }
        } else if (prevState.isSaving && !this.state.isSaving) {
            if (!this.isIOS()) {
                const pdf = new jsPDF()
                this.state.pdf.forEach((img, imgIdx) => {
                    if (imgIdx > 0) pdf.addPage()
                    pdf.addImage(img, 'JPEG', 0, 0)
                })
                pdf.save('form.pdf')
                const res = pdf.output('arraybuffer')
                try {
                    const formName = this.state.regform &&
                        this.state.regform.forms &&
                        this.state.regform.forms[0] &&
                        this.state.regform.forms[0].pages &&
                        this.state.regform.forms[0].pages[0] &&
                        this.state.regform.forms[0].pages[0].elements ?
                        this.state.regform.forms[0].pages[0].elements.filter(e => e.name === 'hccid')[0] : null
                    const attachment = pdf.output('arraybuffer') ? await s3Upload(res, formName && formName.answer ? formName.answer : 'MissingHCCID') : null
                    const reg = await API.post('rpm', '/rpmReg', { body: { ...this.state.regform, attachment } })
                    if (reg.status === 'OK') {
                        this.props.history.push('/')
                    } else {
                        alert('There was a problem saving registration.')
                    }
                } catch (e) {
                    alert(e)
                }
            } else {
                const reg = await API.post('rpm', '/rpmReg', { body: { ...this.state.regform, attachment: 'iOS-Device' } })
                if (reg.status === 'OK') {
                    this.props.history.push('/')
                } else {
                    alert('There was a problem saving registration.')
                }
            }
        }
    }

    handleFormChange() {
        const allSections = []
        const prettyForm = []
        const formPage = 0
        const section = 0
        this.state.regform.forms[this.state.form].pages.forEach(p => {
            return p.elements.forEach(e => {
                if (e.section) {
                    allSections.push(e.section)
                    prettyForm.push(e)
                }
            })
        })
        const sectionsArr = [...new Set(allSections)]
        const sections = sectionsArr.map((s, sidx) => { return { title: s, onClick: () => this.handleStepClick(sidx) } })
        this.setState({ sections, prettyForm, formPage, section })
    }

    handlePDFFormChange(field) {
        let regform = JSON.parse(JSON.stringify(this.state.regform))
        regform.forms[this.state.form].pages[this.state.formPage].elements.forEach((e, idx) => { if (e.id === field.id) regform.forms[this.state.form].pages[this.state.formPage].elements[idx] = field })
        this.setState({ regform })
    }

    handlePrettyFormChange(field) {
        let regform = JSON.parse(JSON.stringify(this.state.regform))
        let prettyForm = JSON.parse(JSON.stringify(this.state.prettyForm))
        let page, element
        regform.forms[this.state.form].pages.forEach((p, pidx) => {
            p.elements.forEach((e, eidx) => {
                if (!element && e.section === field.section && e.id === field.id) {
                    page = pidx
                    element = eidx
                }
            })
        })
        regform.forms[this.state.form].pages[page].elements[element] = field
        prettyForm.forEach((f, fidx) => {
            if (f.section === field.section && f.id === field.id) prettyForm[fidx] = field
        })
        this.setState({ regform, prettyForm })
    }

    handleToPDF() {
        this.setState({ formPage: 0, isSaving: true, pdf: [] })
    }

    handleSavePDF() {
        html2canvas(document.getElementById('pdf-form')).then(canvas => {
            const imgData = canvas.toDataURL('image/png')
            let pdfArr = this.state.pdf.slice()
            pdfArr.push(imgData)
            if (this.state.formPage < this.state.regform.forms[this.state.form].pages.length - 1) {
                this.setState({ pdf: pdfArr, formPage: this.state.formPage + 1 })
            } else {
                this.setState({ pdf: pdfArr, isSaving: false })
            }
        })
    }

    handlePDFFormPageChange(formPage) {
        this.setState({ formPage })
    }

    handleStepClick(e) {
        this.setState({ section: e })
    }

    getView() {
        if (this.state.regform && this.state.regform.forms && this.state.regform.forms[this.state.form].pages[this.state.formPage]) {
            switch (this.state.view) {
                case 'pretty':
                    return this.state.form === null ? null : <PrettyForm form={this.state.prettyForm.filter(f => f.section === this.state.sections[this.state.section].title)} onToPDF={this.handleToPDF} onFormChange={this.handlePrettyFormChange} title={this.state.regform.forms[this.state.form].form} sections={this.state.sections} section={this.state.section} sectionButtonClicked={this.handlePrettyStepButton} />
                case 'pdf':
                    return this.state.form === null ? null : <PDFForm form={this.state.regform.forms[this.state.form].pages[this.state.formPage].elements} onToPDF={this.handleToPDF} onFormChange={this.handlePDFFormChange} pages={this.state.regform.forms[this.state.form].pages.length} page={this.state.formPage} sectionButtonClicked={this.handlePrettyStepButton} />
                default:
                    return null
            }
        }
    }

    handlePrettyStepButton(button) {
        switch (button) {
            case "back":
                if (this.state.view === 'pretty') {
                    if (this.state.section > 0) this.setState({ section: this.state.section - 1 })
                } else if (this.state.view === 'pdf') {
                    if (this.state.formPage > 0) this.setState({ formPage: this.state.formPage - 1 })
                }
                break
            case "quit":
                this.props.history.push('/')
                break
            case "next":
                if (this.state.view === 'pretty') {
                    if (this.state.section < this.state.sections.length - 1) {
                        this.setState({ section: this.state.section + 1 })
                    } else {
                        this.setState({ view: 'pdf' })
                    }
                } else if (this.state.view === 'pdf') {
                    if (this.state.formPage < this.state.regform.forms[this.state.form].pages.length - 1) {
                        this.setState({ formPage: this.state.formPage + 1 })
                    }
                }
                break
            case "pretty": {
                this.setState({ view: 'pretty' })
                break;
            }
            default:
        }
    }

    render() {
        console.log('register --> props, state   ', this.props, this.state, Registration)
        return (
            <div>
                {this.getView()}
            </div>
        )
    }
}

export default Register