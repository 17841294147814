import React, { Component } from 'react'
import moment from 'moment'
import { Line, Bar } from "react-chartjs-2"
import Weight from '../images/weight.svg'
import Running from '../images/running.svg'
import Heartbeat from '../images/heartbeat.svg'
import { displayPhoneFormat, getAlerts } from '../helpers'
import '../containers/dashboard.css'

const bpCalc = 0.00750062
const wtCalc = 0.00220462

class MemberTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            width: 0,
            height: 0,
            displayFull: true,
            displaySmall: false,
            displayMedium: false,
            sort: null,
            settings: false
        }
        this.updateWindowSize = this.updateWindowSize.bind(this)
    }

    componentDidMount() {
        this.updateWindowSize()
        window.addEventListener('resize', this.updateWindowSize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize) 
    }

    updateWindowSize() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            displayFull: window.innerWidth > 1155,
            displaySmall: window.innerWidth < 670,
            displayMedium: window.innerWidth < 770
        })
    }

    handleSelect(idx) {
        this.props.onSelected(idx)
    }

    handleExpand(idx) {
        this.props.onExpanded(idx)
    }

    handleSettings() {
        this.setState({settings: !this.state.settings})
    }

    handleFilter(fltr) {
        this.props.onFilter(fltr)
        this.setState({settings: false})
    }

    getMeasurement(id) {
        const meas = this.props.data.measures.filter(device => device.imei === id)
        if (meas.length === 0) return null
        const curr = meas.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })
        return curr[0]
    }

    get5dMeasurement(id) {
        const meas = this.props.data.measures.filter(device => device.imei === id && moment(device.ts).format('l') === moment(new Date()).subtract(5, 'days').format('l'))
        if (meas.length === 0) return null
        const curr = meas.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })
        return curr[0]
    }

    getMeasurements(id) {
        const measurements = this.props.data.measures.filter(device => device.imei === id)
        if (measurements.length === 0) return []
        const sorted = measurements.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        })
        return sorted
    }

    getRows() {
        return (
            this.props.data && this.props.data.mbrs && this.props.data.mbrs.sort((a, b) => {
                let x = a.bpAlert || a.w1dAlert || a.bpAlert
                let y = b.bpAlert || b.w1dAlert || b.bpAlert
                if (x && !y) return -1
                if (!x && y) return 1
                return 0
            }).map((mbr, midx) => {
                const w = mbr.scaleId ? this.getMeasurement(mbr.scaleId) : null
                const bp = mbr.bpmId ? this.getMeasurement(mbr.bpmId) : null
                const ws = mbr.scaleId ? this.getMeasurements(mbr.scaleId) : null
                const w5d = mbr.scaleId ? this.get5dMeasurement(mbr.scaleId) : null
                const bps = mbr.bpmId ? this.getMeasurements(mbr.bpmId) : null
                const alerts = getAlerts(mbr, w, bp, w5d)
                const dataWS = ws ? ws.map(v => parseInt(v.values.weight * wtCalc)) : []
                const dataBP = bps ? bps.map(v => parseInt(v.values.pulse)) : []
                const dataS = bps ? bps.map(v => parseInt(v.values.systolic * bpCalc)) : []
                const dataD = bps ? bps.map(v => parseInt(v.values.diastolic * bpCalc)) : []
                return (
                    <div key={`mbr-${midx}`} >
                        <div className={`Dashboard-Row-Container ${this.props.expanded.indexOf(mbr.hccid) >= 0 ? 'Dashboard-Row-Selected' : ''}`}>
                            <div className="Dashboard-Row-Status" style={{ backgroundColor: alerts.all.color }}></div>
                            <div className="Dashboard-Row-Label Dashboard-Row-Selectable" style={{ width: "145px", color: "#3771BE" }} onClick={() => this.handleSelect(mbr.hccid)}>{mbr.patientName || ''}</div>
                            {this.state.displayFull && <div className="Dashboard-Row-Label" style={{ width: "120px" }}>{mbr.contactNumber ? displayPhoneFormat(mbr.contactNumber.replace(/\D/g, "")) : ''}</div>}
                            {!this.state.displaySmall && <div className="Dashboard-Row-Label" style={{ width: "180px" }}>
                                {w ? `${parseInt(w.values.weight * wtCalc)} lbs` : null}
                                {!alerts.w1d.good && (alerts.w1d.amount) ? <i className="fas fa-caret-down" style={{ color: alerts.w1d.color, marginLeft: '5px', transform: 'rotate(180deg)' }}></i> : null}
                                {!alerts.w1d.good ? <span style={{ marginLeft: '4px', color: alerts.w1d.color }}>{alerts.w1d.amount}</span> : null}
                                {!alerts.w5d.good && alerts.w5d.amount ? <i className="fas fa-caret-down" style={{ color: alerts.w5d.color, marginLeft: '5px', transform: 'rotate(180deg)' }}></i> : null}
                                {!alerts.w5d.good ? <span style={{ marginLeft: '4px', color: alerts.w5d.color }}>{alerts.w5d.amount}</span> : null}
                            </div>}
                            {!this.state.displaySmall && !this.state.displayMedium && <div className="Dashboard-Row-Label" style={{ width: "140px" }}>{w ? moment(w.ts).format('M/D/YYYY h:mm A') : ''}</div>}
                            {!this.state.displaySmall && <div className="Dashboard-Row-Label" style={{ width: "220px", color: !alerts.bp.good ? alerts.bp.color : null }}>
                                {bp ? `${parseInt(bp.values.systolic * bpCalc)}/${parseInt(bp.values.diastolic * bpCalc)} mmHg` : ''}
                                {!alerts.bp.good ? <i className="fas fa-caret-down" style={{ color: alerts.bp.color, marginLeft: '5px', transform: 'rotate(180deg)' }}></i> : null}
                                {/*!alerts.bp.good ? <span style={{ marginLeft: '4px', color: alerts.bp.color }}>{alerts.bp.amount}</span> : null*/}
                            </div>}
                            {/*<div className="Dashboard-Row-Label" style={{ width: "80px" }}>{bp ? `${bp.values.pulse} bpm` : ''}</div>*/}
                            {!this.state.displaySmall && !this.state.displayMedium && <div className="Dashboard-Row-Label" style={{ width: "140px" }}>{bp ? moment(bp.ts).format('M/D/YYYY h:mm A') : ''}</div>}
                            <div className="Dashboard-Row-Label" style={{ width: "60px", cursor: 'pointer' }} onClick={() => this.handleExpand(mbr.hccid)}><i className="far fa-caret-square-down Dashboard-Expand-Icon"></i></div>
                        </div>
                        {this.props.expanded.indexOf(mbr.hccid) >= 0 && <div className="Dashboard-Details-Container">
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Details-Text-Title">Personal Information</div>
                                <div className="Dashboard-Details-Text-Subtitle">ADDRESS</div>
                                {
                                    mbr.address && mbr.address.split(",").map((addr, aidx) => {
                                        return <div key={`addr-${aidx}`} className="Dashboard-Details-Text-Value">{addr}</div>
                                    })
                                }
                                <div className="Dashboard-Details-Text-Subtitle">PHONE</div>
                                <div className="Dashboard-Details-Text-Value">{mbr.contactNumber ? displayPhoneFormat(mbr.contactNumber.replace(/\D/g, "")) : ''}</div>
                                <div className="Dashboard-Details-Text-Subtitle">HCCID</div>
                                <div className="Dashboard-Details-Text-Value">{mbr.hccid || ''}</div>
                            </div>
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Details-Text-Title">Medical Team</div>
                                <div className="Dashboard-Details-Text-Subtitle">PCP</div>
                                <div className="Dashboard-Details-Text-Value">{mbr.pcp || ''}</div>
                                <div className="Dashboard-Details-Text-Subtitle">PCP PHONE</div>
                                <div className="Dashboard-Details-Text-Value">{mbr.pcpNum ? displayPhoneFormat(mbr.pcpNum.replace(/\D/g, "")) : ''}</div>
                                <div className="Dashboard-Details-Text-Subtitle">NURSE</div>
                                <div className="Dashboard-Details-Text-Value">{mbr.nurse || ''}</div>
                            </div>
                            <div className="Dashboard-Details-Text-Container" style={{ width: '200px' }}>
                                <div className="Dashboard-Details-Text-Title">Status</div>
                                <div className="Dashboard-Details-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">1-Day Weight:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.w1d.color }}></div>
                                </div>
                                <div className="Dashboard-Details-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">5-Day Weight:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.w5d.color }}></div>
                                </div>
                                <div className="Dashboard-Details-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">Blood Pressure:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.bp.color }}></div>
                                </div>
                            </div>
                            <div className="Dashboard-Details-Graph-Container-1">
                                <div className="Dashboard-Details-Graph-Title">
                                    <img src={Weight} alt="" className="Dashboard-Details-Graph-Title-Left" />
                                    <div className="Dashboard-Details-Graph-Title-Left">Weight</div>
                                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: alerts.w1d.color !== '#66bb6a' || alerts.w5d.color !== '#66bb6a' ? (alerts.w1d.color === '#ef5350' || alerts.w5d.color === '#ef5350' ? '#ef5350' : '#ffd600') : '#fff' }}>{w ? `${parseInt(w.values.weight * 0.00220462)} lbs` : ''}</div>
                                </div>
                                {ws && <Line data={{
                                    labels: ws && ws.map(v => moment(v.ts).format('l')),
                                    datasets: [
                                        {
                                            label: "Weight",
                                            strokeColor: "#3771be",
                                            pointColor: "#5399f4",
                                            pointStrokeColor: "#fff",
                                            pointHighlightFill: "#fff",
                                            pointHighlightStroke: "#fff",
                                            data: dataWS
                                        }
                                    ]
                                }} width={220} height={165} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: {display: false} }}
                                />}
                            </div>
                            <div className="Dashboard-Details-Graph-Container-2">
                                <div className="Dashboard-Details-Graph-Title">
                                    <img src={Running} alt="" className="Dashboard-Details-Graph-Title-Left" />
                                    <div className="Dashboard-Details-Graph-Title-Left">Heart Rate</div>
                                    <div className="Dashboard-Details-Graph-Title-Right">{bp ? `${bp.values.pulse} bpm` : ''}</div>
                                </div>
                                {bps && <Bar data={{
                                    labels: bps && bps.map(v => moment(v.ts).format('l')),
                                    datasets: [
                                        {
                                            label: "Pulse",
                                            strokeColor: "#3771be",
                                            fillColor: "#82defa",
                                            HighlightFill: "#fff",
                                            HighlightStroke: "#fff",
                                            data: dataBP
                                        }
                                    ]
                                }} width={220} height={165} options={{ datasetFill: false, scaleGridLineColor: '#fff', scales: { yAxes: [{ ticks: { suggestedMin: 50 } }] }, legend: {display: false} }}
                                />}
                            </div>
                            <div className="Dashboard-Details-Graph-Container-3">
                                <div className="Dashboard-Details-Graph-Title">
                                    <img src={Heartbeat} alt="" className="Dashboard-Details-Graph-Title-Left" />
                                    <div className="Dashboard-Details-Graph-Title-Left">BP</div>
                                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: alerts.bp.color === '#66bb6a' ? '#fff' : alerts.bp.color }}>{bp ? `${parseInt(bp.values.systolic * 0.00750062)}/${parseInt(bp.values.diastolic * 0.00750062)} mmHg` : ''}</div>
                                </div>
                                {bps && <Line data={{
                                    labels: bps && bps.map(v => moment(v.ts).format('l')),
                                    datasets: [
                                        {
                                            label: "Systolic",
                                            strokeColor: "#41c2e9",
                                            pointColor: "#5399f4",
                                            pointStrokeColor: "#fff",
                                            pointHighlightFill: "#fff",
                                            pointHighlightStroke: "#fff",
                                            data: dataS
                                        },
                                        {
                                            label: "Diastolic",
                                            strokeColor: "#82defa",
                                            pointColor: "#5399f4",
                                            pointStrokeColor: "#fff",
                                            pointHighlightFill: "#fff",
                                            pointHighlightStroke: "#fff",
                                            data: dataD
                                        }
                                    ]
                                }} width={220} height={165} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: {display: false} }}
                                />}
                            </div>
                        </div>}
                    </div>
                )
            }))

    }

    getTable() {
        return (
            <div className="Dashboard-Table">
                <div className="Dashboard-Header">
                    <div className="Dashboard-Header-Label" style={{ width: "150px" }}>PATIENT NAME</div>
                    {this.state.displayFull && <div className="Dashboard-Header-Label" style={{ width: "120px" }}>PATIENT PHONE</div>}
                    {!this.state.displaySmall && <div className="Dashboard-Header-Label" style={{ width: "180px" }}>WEIGHT</div>}
                    {!this.state.displaySmall && !this.state.displayMedium && <div className="Dashboard-Header-Label" style={{ width: "140px" }}>WEIGHT TIME</div>}
                    {!this.state.displaySmall && <div className="Dashboard-Header-Label" style={{ width: "220px" }}>BLOOD PRESSURE</div>}
                    {/*<div className="Dashboard-Header-Label" style={{ width: "80px" }}>PULSE</div>*/}
                    {!this.state.displaySmall && !this.state.displayMedium && <div className="Dashboard-Header-Label" style={{ width: "140px" }}>BP TIME</div>}
                    <div className="Dashboard-Header-Label" style={{ width: "60px" }}>EXPAND</div>
                    {false && <div className="Dashboard-Header-Label Dashboard-Header-Label-Clickable" style={{ width: "25px" }} onClick={() => this.handleSettings() }><i className="fas fa-cog" style={{color: this.props.filter === 'all' ? "#fff" : "rgb(239, 83, 80)"}}></i></div>}
                    {this.state.settings && false && <div className="Dashboard-Header-Menu">
                                                <div className={`Dashboard-Header-Menu-Item-Ignore`}>Filter</div>
                                                <div className={`Dashboard-Header-Menu-Item ${this.props.filter === "all" ? "Dashboard-Header-Menu-Item-Selected" : ""}`} onClick={() => this.handleFilter('all')}>All</div>
                                                <div className={`Dashboard-Header-Menu-Item ${this.props.filter === "jax" ? "Dashboard-Header-Menu-Item-Selected" : ""}`} onClick={() => this.handleFilter('jax')}>Jacksonville</div>
                                                <div className={`Dashboard-Header-Menu-Item ${this.props.filter === "penntall" ? "Dashboard-Header-Menu-Item-Selected" : ""}`} onClick={() => this.handleFilter('penntall')}>Pensacola/Tallahassee</div>
                                            </div>}
                </div>
                <div className="Dashboard-Rows-Container">
                    {this.getRows()}
                </div>
            </div>)
    }

    render() {
        return (
            this.getTable()
        )
    }
}

export default MemberTable
