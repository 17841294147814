import React, { Component } from 'react'
import FieldTB from '../fields/fieldTb'
import FieldCBGRB from './fieldCbgrb'
import './fields.css'

class FieldCB extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(field, val, idx) {
        this.props.onValueChange(this.props.field, val, idx)
    }

    render() {
        return (
            <div className="CBG-Container">
                {this.props.field.label ? <div className="CB-PDF-Main-Label">{this.props.field.label}</div> : null}
                <div className="Multi-PDF-Container">
                    {
                        this.props.field.options.map((t, tidx) => {
                            switch (t.type) {
                                case "cbgrb":
                                    return <div className="Multi-PDF-Item" key={`multi-${this.props.field.id}-${tidx}`}><FieldCBGRB field={{...t, answer: this.props.field.answer && this.props.field.answer[tidx] ? this.props.field.answer[tidx] : null}} onValueChange={this.handleChange} idx={tidx} /></div>
                                case "tb":
                                    return <div className="Multi-PDF-Item" key={`multi-${this.props.field.id}-${tidx}`}><FieldTB field={{...t, answer: this.props.field.answer && this.props.field.answer[tidx] ? this.props.field.answer[tidx] : null}} onValueChange={this.handleChange} idx={tidx} /></div>
                                default:
                                    return null
                            }
                        })
                    }
                </div>

            </div>
        )
    }
}

export default FieldCB