import React, { Component } from "react"
import RightArrow from '../images/arrow-alt-right.svg'
import Chart from '../images/chart-line.svg'
import Clipboard from '../images/clipboard-list-check.svg'
import HeartBeat from '../images/monitor-heart-rate.svg'
import "./home.css"

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentWillMount() {
    if (!this.props.user) { this.props.history.push('/') }
  }

  handleClick(link) {
    this.props.history.push(link)
  }

  render() {
    console.log('home    ', this.props)
    return (
      <div className="Home">
        <div className="Login-Img"><img src={HeartBeat} alt="Welcome" /></div>
        <div className="Login-Title">
          <span className="Login-Thick">RPM Pilot</span>
          <span className="Login-Thin">Member Hub</span>
        </div>
        <div className="Home-Cards-Row">
          <div className="Home-Cards-Container">
            <div className="Home-Card-Container" onClick={() => this.handleClick('/register')}>
              <div className="Home-Card-Inner">
                <div className="Home-Card-Left" style={{backgroundColor: "rgb(49, 160, 86)"}}><img src={Clipboard} alt="Register" /></div>
                <div className="Home-Card-Right">
                <div className="Home-Card-Arrow">
                    <img src={RightArrow} alt="" />
                  </div>
                  <div className="Home-Card-Title">
                    Enroll Participants
                  </div>
                  <div className="Home-Card-Details">
                    Access Forms to register patients into the Remote Patient Monitoring Pilot.
                  </div>
                </div>
              </div>
            </div>
            <div className="Home-Card-Container" style={{ marginLeft: '30px' }} onClick={() => this.handleClick('/dashboard')}>
            <div className="Home-Card-Inner">
                <div className="Home-Card-Left" style={{backgroundColor: "#42A5F5"}}><img src={Chart} alt="Dashboard" /></div>
                <div className="Home-Card-Right">
                  <div className="Home-Card-Arrow">
                    <img src={RightArrow} alt="" />
                  </div>
                  <div className="Home-Card-Title">
                    Patient Dashboard
                  </div>
                  <div className="Home-Card-Details">
                    View remote biometric readings and track patient changes in health status and alerts.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home