import React, { Component } from 'react'
import moment from 'moment'
import { Line, Bar } from "react-chartjs-2"
import { Glyphicon } from 'react-bootstrap'
import Weight from '../images/weight.svg'
import Running from '../images/running.svg'
import Heartbeat from '../images/heartbeat.svg'
import { displayPhoneFormat, getAlerts, displayDateFormat } from '../helpers'
import '../containers/dashboard.css'


const bpCalc = 0.00750062
const wtCalc = 0.00220462

class MemberCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cardMenu: null,
            width: 0,
            height: 0,
            displayFull: true,
            displaySmall: false,
            displayMedium: false,
            alerts: null
        }
    }

    componentWillMount() {
        const alerts = this.props.mbr ? getAlerts(this.props.mbr, this.props.ws[0] || null, this.props.bps[0] || null, this.props.w5d) : null
        this.setState({ alerts })
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.mbr) !== JSON.stringify(nextProps.mbr) || nextProps.ws.length > this.props.ws.length || nextProps.bps.length > this.props.bps.length) {
            const alerts = nextProps.mbr ? getAlerts(nextProps.mbr, nextProps.ws[0] || null, nextProps.bps[0] || null, nextProps.w5d) : null
            this.setState({ alerts })
        }
    }

    handleReturn() {
        this.props.onReturn()
    }

    handleClearAlert(alert) {
        this.props.alertCleard(alert)
    }

    handleCardMenuSelected(cardMenu) {
        this.setState({ cardMenu })
    }

    getWeightGraph(alerts, w, ws) {
        const dataWS = ws ? ws.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        }).map(v => parseInt(v.values.weight * wtCalc)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-1">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Weight} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Weight</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: alerts.w1d.color !== '#66bb6a' || alerts.w5d.color !== '#66bb6a' ? (alerts.w1d.color === '#ef5350' || alerts.w5d.color === '#ef5350' ? '#ef5350' : '#ffd600') : '#fff' }}>{w ? `${parseInt(w.values.weight * 0.00220462)} lbs` : ''}</div>
                </div>
                {ws && <Line data={{
                    labels: ws && ws.map(v => moment(v.ts).format('l')),
                    datasets: [
                        {
                            label: "Weight",
                            strokeColor: "#3771be",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataWS
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: {display: false} }}
                />}
            </div>
        )
    }

    getPulseGraph(bp, bps) {
        const dataP = bps ? bps.map(v => parseInt(v.values.pulse)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-2">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Running} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Heart Rate</div>
                    <div className="Dashboard-Details-Graph-Title-Right">{bp ? `${bp.values.pulse} bpm` : ''}</div>
                </div>
                {bps && <Bar data={{
                    labels: bps && bps.map(v => moment(v.ts).format('l')),
                    datasets: [
                        {
                            label: "Pulse",
                            strokeColor: "#3771be",
                            fillColor: "#82defa",
                            HighlightFill: "#fff",
                            HighlightStroke: "#fff",
                            data: dataP
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', scales: { yAxes: [{ ticks: { suggestedMin: 50 } }] }, legend: {display: false} }}
                />}
            </div>
        )
    }

    getBPGraph(alerts, bp, bps) {
        const dataS = bps ? bps.map(v => parseInt(v.values.systolic * bpCalc)) : []
        const dataD = bps ? bps.map(v => parseInt(v.values.diastolic * bpCalc)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-3">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Heartbeat} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">BP</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: alerts.bp.color === '#66bb6a' ? '#fff' : alerts.bp.color }}>{bp ? `${parseInt(bp.values.systolic * 0.00750062)}/${parseInt(bp.values.diastolic * 0.00750062)} mmHg` : ''}</div>
                </div>
                {bps && <Line data={{
                    labels: bps && bps.map(v => moment(v.ts).format('l')),
                    datasets: [
                        {
                            label: "Systolic",
                            strokeColor: "#41c2e9",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataS
                        },
                        {
                            label: "Diastolic",
                            strokeColor: "#82defa",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataD
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: {display: false} }}
                />}
            </div>
        )
    }

    getHistory(type, vals) {
        vals = JSON.parse(JSON.stringify(vals)).sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).splice(0, 10)
        switch (type) {
            case 'weight':
                return (
                    vals.map((val, vidx) => {
                        const curr = parseInt(val.values.weight * wtCalc)
                        const prev = vidx < vals.length - 1 ? parseInt(vals[vidx + 1].values.weight * 0.00220462) : null
                        return (
                            <div key={`history-${vidx}`} className="Dashboard-Card-Text-Value"><span>{`${curr} lbs`}</span>{curr < prev ? <i className='fas fa-caret-down' style={{ marginLeft: '10px', color: '#66BB6A' }}></i> : (prev && curr > prev ? <i className='fas fa-caret-down' style={{ marginLeft: '10px', color: '#EF5350', transform: 'rotate(180deg)' }}></i> : null)}{curr < prev ? <span style={{ marginLeft: '10px', color: '#66BB6A' }}>{`${prev - curr} lbs`}</span> : (prev && curr > prev ? <span style={{ marginLeft: '10px', color: '#EF5350' }}>{`${curr - prev} lbs`}</span> : null)}<span style={{ marginLeft: '10px', float: 'right' }}>{moment(val.ts).format('M/D/YYYY h:mm a')}</span></div>
                        )
                    })
                )
            case 'bp':
                return (
                    vals.map((val, vidx) => {
                        const currDx = parseInt(val.values.diastolic * bpCalc)
                        const currSx = parseInt(val.values.systolic * bpCalc)
                        return (
                            <div key={`history-${vidx}`} className="Dashboard-Card-Text-Value"><span style={{ color: currSx >= 140 && currDx >= 90 ? '#EF5350' : '#1D4883' }}>{`${currSx}/${currDx} mmHg`}</span><span style={{ marginLeft: '10px', float: 'right' }}>{moment(val.ts).format('M/D/YYYY h:mm a')}</span></div>
                        )
                    })
                )
            case 'pulse':
                return (
                    vals.map((val, vidx) => {
                        return (
                            <div key={`history-${vidx}`} className="Dashboard-Card-Text-Value"><span>{`${val.values.pulse} bpm`}</span><span style={{ marginLeft: '10px', float: 'right' }}>{moment(val.ts).format('M/D/YYYY h:mm a')}</span></div>
                        )
                    })
                )
            default:
                return null
        }
    }

    getAggrWeight(vals) {
        vals = JSON.parse(JSON.stringify(vals)).sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).splice(0, 10)
        const max = vals.sort((a, b) => {
            let x = a.values.weight
            let y = b.values.weight
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })[0] || null

        const min = vals.sort((a, b) => {
            let x = a.values.weight
            let y = b.values.weight
            if (x > y) return 1
            if (x < y) return -1
            return 0
        })[0] || null

        let avg = 0
        vals.forEach(val => avg += val.values.weight)
        avg = parseInt(vals.length > 0 ? (avg * 0.00220462) / vals.length : 0)

        return (
            <div>
                <div className="Dashboard-Card-Text-Value">{`Maximum: ${max ? parseInt(max.values.weight * 0.00220462) : 0} lbs`}</div>
                <div className="Dashboard-Card-Text-Value">{`Average: ${avg} lbs`}</div>
                <div className="Dashboard-Card-Text-Value">{`Minimum: ${min ? parseInt(min.values.weight * 0.00220462) : 0} lbs`}</div>
            </div>
        )
    }

    getCardDetail(alerts, bps, ws) {
        const currW = ws.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })[0] || null
        const currBP = bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        })[0] || null
        const sortedbps = bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        })
        switch (this.state.cardMenu) {
            case 'weight':
                return (
                    this.getWeightGraph(alerts, currW, ws)
                )
            case 'pulse':
                return (
                    this.getPulseGraph(currBP, sortedbps)
                )
            case 'bp':
                return (
                    this.getBPGraph(alerts, currBP, sortedbps)
                )
            default:
                return (
                    <div>
                        {this.getWeightGraph(alerts, currW, ws)}
                        {this.getPulseGraph(currBP, sortedbps)}
                        {this.getBPGraph(alerts, currBP, sortedbps)}
                    </div>
                )
        }
    }

    getRest() {
        switch (this.state.cardMenu) {
            case 'weight':
                return (
                    <div>
                        <div className="Dashboard-Details-Text-Container">
                            <div className="Dashboard-Card-Text-Title-Med">Daily Tracking</div>
                            {this.getHistory('weight', this.props.ws)}
                        </div>
                        <div className="Dashboard-Details-Text-Container">
                            <div className="Dashboard-Card-Text-Title-Med">Daily Tracking</div>
                            {this.getAggrWeight(this.props.ws)}
                        </div>
                    </div>
                )
            case 'pulse':
                return (
                    <div>
                        <div className="Dashboard-Details-Text-Container">
                            <div className="Dashboard-Card-Text-Title-Med">Daily Tracking</div>
                            {this.getHistory('pulse', this.props.bps)}
                        </div>
                    </div>
                )
            case 'bp':
                return (
                    <div>
                        <div className="Dashboard-Details-Text-Container">
                            <div className="Dashboard-Card-Text-Title-Med">Daily Tracking</div>
                            {this.getHistory('bp', this.props.bps)}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    getCard() {
        const alerts = this.state.alerts || null
        //const alerts = this.props.mbr && this.props.ws[0] && this.props.bps[0] ? getAlerts(this.props.mbr, this.props.ws[0], this.props.bps[0]) : null
        //console.log('alerts    ', alerts)
        return (
            this.props.mbr ?
                <div className="Dashboard-Card">
                    <div className="Dashboard-Card-Row">
                        <div className="Dashboard-Card-Button Dashboard-Card-Button-Left" onClick={() => this.handleReturn()}><i className="fas fa-caret-down" style={{ transform: "rotate(90deg)", marginRight: "10px" }}></i>Return to Patient List</div>
                    </div>
                    <div className="Dashboard-Card-Section Dashboard-Card-Section-Left">
                        <div className="Dashboard-Card-Row Dashboard-Card-Row-Height">
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Initial-Container"><div className="Dashboard-Card-Initial">{this.props.mbr.patientName ? this.props.mbr.patientName.split(' ').map(n => n[0]).join("") : ''}</div></div>
                            </div>
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Text-Title-Big">{this.props.mbr.patientName}</div>
                                <div className="Dashboard-Card-Text-Subtitle">ADDRESS</div>
                                {
                                    this.props.mbr.address && this.props.mbr.address.split(",").map((addr, aidx) => {
                                        return <div key={`addr-${aidx}`} className="Dashboard-Details-Text-Value">{addr}</div>
                                    })
                                }
                                <div className="Dashboard-Card-Text-Subtitle">PHONE</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.contactNumber ? displayPhoneFormat(this.props.mbr.contactNumber.replace(/\D/g, "")) : ''}</div>
                                <div className="Dashboard-Card-Text-Subtitle">DOB</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.dob ? displayDateFormat(this.props.mbr.dob.replace(/\D/g, "")) : ''}</div>
                                <div className="Dashboard-Card-Text-Subtitle">HCCID</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.hccid || ''}</div>
                            </div>
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Text-Title-Med">Medical Team</div>
                                <div className="Dashboard-Card-Text-Subtitle">PRIMARY CARE PHYSICIAN</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.pcp || ''}</div>
                                <div className="Dashboard-Card-Text-Subtitle">PCP OFFICE PHONE</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.pcpNum ? displayPhoneFormat(this.props.mbr.pcpNum.replace(/\D/g, "")) : ''}</div>
                                <div className="Dashboard-Card-Text-Subtitle">Nurse</div>
                                <div className="Dashboard-Card-Text-Value">{this.props.mbr.nurse || ''}</div>
                            </div>
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Text-Title-Med">Device Details</div>
                                <div className="Dashboard-Card-Text-Subtitle">SCALE</div>
                                <div className="Dashboard-Card-Text-Value">{`Device ID: ${this.props.mbr.scaleId || ''}`}</div>
                                <div className="Dashboard-Card-Text-Subtitle">BLOOD PRESSURE CUFF</div>
                                <div className="Dashboard-Card-Text-Value">{`Device ID: ${this.props.mbr.bpmId || ''}`}</div>
                            </div>
                        </div>
                        <div className="Dashboard-Card-Row">
                            <div className="Dashboard-Card-Menu-Container" onClick={() => this.handleCardMenuSelected('all')}>
                                <div className={`Dashboard-Card-Text-Title-Med ${!this.state.cardMenu || this.state.cardMenu === 'all' ? 'Dashboard-Card-Menu-Selected' : ''}`}>All</div>
                            </div>
                            <div className="Dashboard-Card-Menu-Container" onClick={() => this.handleCardMenuSelected('weight')}>
                                <div className={`Dashboard-Card-Text-Title-Med ${this.state.cardMenu && this.state.cardMenu === 'weight' ? 'Dashboard-Card-Menu-Selected' : ''}`}>Weight</div>
                            </div>
                            <div className="Dashboard-Card-Menu-Container" onClick={() => this.handleCardMenuSelected('pulse')}>
                                <div className={`Dashboard-Card-Text-Title-Med ${this.state.cardMenu && this.state.cardMenu === 'pulse' ? 'Dashboard-Card-Menu-Selected' : ''}`}>Heart Rate</div>
                            </div>
                            <div className="Dashboard-Card-Menu-Container" onClick={() => this.handleCardMenuSelected('bp')}>
                                <div className={`Dashboard-Card-Text-Title-Med ${this.state.cardMenu && this.state.cardMenu === 'bp' ? 'Dashboard-Card-Menu-Selected' : ''}`}>Blood Pressure</div>
                            </div>
                        </div>
                        <div className="Dashboard-Card-Row">
                            {this.getCardDetail(alerts, this.props.bps, this.props.ws)}
                            {this.getRest()}
                        </div>
                    </div>
                    <div className="Dashboard-Card-Section Dashboard-Card-Section-right">
                        <div className="Dashboard-Card-Row Dashboard-Card-Row-Height">
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Text-Title-Med">Status Alerts</div>
                                {
                                    !alerts.w1d.good &&
                                    <div>
                                        <div className="Dashboard-Card-Row-Right">
                                            <div className="Dashboard-Card-Text-Alert">1-DAY WEIGHT</div>
                                            <div className="Dashboard-Card-Button Dashboard-Card-Button-Right" onClick={() => this.handleClearAlert('w1dAlert')}><i className="fas fa-clipboard-check" style={{ marginRight: "10px" }}></i>{this.props.updating.indexOf('w1dAlert') >= 0 && <Glyphicon glyph="refresh" className="Alerts-Updating" />}Clear Alert</div>
                                        </div>
                                        <div className="Dashboard-Card-Text-Value">{`${moment(alerts.w1d.date).subtract(1, 'day').format('l')} - ${moment(alerts.w1d.date).format('l')}`}</div>
                                        <div className="Dashboard-Card-Text-Value"><span>{`${alerts.w1d.from && parseInt(alerts.w1d.from)} - ${alerts.w1d.to && parseInt(alerts.w1d.to)}`}</span><i className="fas fa-caret-down" style={{ color: alerts.w1d.color, marginLeft: '5px', transform: 'rotate(180deg)' }}></i><span style={{ marginLeft: '4px', color: alerts.w1d.color }}>{`${alerts.w1d.to && alerts.w1d.from && parseInt(alerts.w1d.to - alerts.w1d.from)} lbs`}</span></div>
                                    </div>
                                }
                                {
                                    !alerts.w5d.good &&
                                    <div>
                                        <div className="Dashboard-Card-Row-Right">
                                            <div className="Dashboard-Card-Text-Alert">5-DAY WEIGHT</div>
                                            <div className="Dashboard-Card-Button Dashboard-Card-Button-Right" onClick={() => this.handleClearAlert('w5dAlert')}><i className="fas fa-clipboard-check" style={{ marginRight: "10px" }}></i>{this.props.updating.indexOf('w5dAlert') >= 0 && <Glyphicon glyph="refresh" className="Alerts-Updating" />}Clear Alert</div>
                                        </div>
                                        <div className="Dashboard-Card-Text-Value">{`${moment(alerts.w5d.date).subtract(5, 'day').format('l')} - ${moment(alerts.w5d.date).format('l')}`}</div>
                                        <div className="Dashboard-Card-Text-Value"><span>{`${alerts.w5d.from && parseInt(alerts.w5d.from)} - ${alerts.w5d.to && parseInt(alerts.w5d.to)}`}</span><i className="fas fa-caret-down" style={{ color: alerts.w5d.color, marginLeft: '5px', transform: 'rotate(180deg)' }}></i><span style={{ marginLeft: '4px', color: alerts.w5d.color }}>{`${alerts.w5d.to && alerts.w5d.from && parseInt(alerts.w5d.to - alerts.w5d.from)} lbs`}</span></div>
                                    </div>
                                }
                                {
                                    !alerts.bp.good &&
                                    <div>
                                        <div className="Dashboard-Card-Row-Right">
                                            <div className="Dashboard-Card-Text-Alert">BLOOD PRESSURE</div>
                                            <div className="Dashboard-Card-Button Dashboard-Card-Button-Right" onClick={() => this.handleClearAlert('bpAlert')}><i className="fas fa-clipboard-check" style={{ marginRight: "10px" }}></i>{this.props.updating.indexOf('bpAlert') >= 0 && <Glyphicon glyph="refresh" className="Alerts-Updating" />}Clear Alert</div>
                                        </div>
                                        <div className="Dashboard-Card-Text-Value"><span>{alerts.bp.date}</span><span style={{ marginLeft: '4px', color: alerts.bp.color }}>{alerts.bp.amount}</span></div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="Dashboard-Card-Row">
                            <div className="Dashboard-Details-Text-Container">
                                <div className="Dashboard-Card-Text-Title-Med">Patient Alerts</div>
                                <div className="Dashboard-Card-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">1-Day Weight:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.w1d.color }}></div>
                                </div>
                                <div className="Dashboard-Card-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">5-Day Weight:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.w5d.color }}></div>
                                </div>
                                <div className="Dashboard-Card-Status-Container">
                                    <div className="Dashboard-Details-Status-Text">Blood Pressure:</div>
                                    <div className="Dashboard-Details-Status" style={{ backgroundColor: alerts.bp.color }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="Dashboard-Card">
                    <div className="Dashboard-Card-Row">
                        <div className="Dashboard-Card-Button Dashboard-Card-Button-Left" onClick={() => this.handleUnSelect()}><i className="fas fa-caret-down" style={{ transform: "rotate(90deg)", marginRight: "10px" }}></i>Return to Patient List</div>
                    </div>
                </div>
        )
    }

    render() {
        return (
            this.props.mbr && this.props.bps && this.props.ws && this.getCard()
        )
    }
}

export default MemberCard
