import React, { Component } from 'react'
import moment from 'moment'
import './fields.css'

class FieldTB extends Component {

    componentWillMount() {
        if(!this.props.field.answer) {
            if(this.props.field.default) {
                switch(this.props.field.default) {
                    case 'today':
                        this.props.onValueChange(this.props.field, moment(new Date()).format("MMDDYYYY"))
                        break
                    default: 
                        break
                }
            }
        }
    }

    handleChange(val) {
        if(this.props.idx) {
            this.props.onValueChange(this.props.field, {"text": val}, this.props.idx)
        } else {
            this.props.onValueChange(this.props.field, val)
        }
    }

    displayDateFormat(date) {
        console.log(date)
        let newDate = date.substring(0,2) 
        if(date.length > 2) newDate += "/" + date.substring(2,4)
        if(date.length > 4) newDate += "/" + date.substring(4)
        return newDate
    }

    displayPhoneFormat(phone) {
        let newPhone = "(" + phone.substring(0,3) 
        if(phone.length > 3) newPhone += ") " + phone.substring(3,6)
        if(phone.length > 6) newPhone += "-" + phone.substring(6)
        return newPhone
    }

    displaySSNFormat(ssn) {
        let newSSN = ssn.substring(0,3) 
        if(ssn.length > 3) newSSN += "-" + ssn.substring(3,5)
        if(ssn.length > 5) newSSN += "-" + ssn.substring(5)
        return newSSN
    }

    displayZipFormat(zip) {
        let newZip = zip.substring(0,5) 
        if(zip.length > 5) newZip += "-" + zip.substring(5)
        return newZip
    }

    getTB() {
        const style = this.props.field.max ? {width: `${(this.props.field.max * 9) + 10}px`} : {width: "auto"}
        
        switch(this.props.field.subtype || "text") {
            case "date": 
                return <input className="TB-Input" style={ style } type="text" onChange={ event => this.handleChange(event.target.value.replace(/\D/g, "")) } value={this.props.field.answer ? (this.props.idx ? this.displayDateFormat(this.props.field.answer.text) : this.displayDateFormat(this.props.field.answer)) : ""} maxLength={this.props.field.max ? this.props.field.max : null} />
            case "phone": 
                return <input className="TB-Input" style={ style } type="text" onChange={ event => this.handleChange(event.target.value.replace(/\D/g, "")) } value={this.props.field.answer ? (this.props.idx ? this.displayPhoneFormat(this.props.field.answer.text) : this.displayPhoneFormat(this.props.field.answer)) : ""} maxLength={this.props.field.max ? this.props.field.max : null} />
            case "ssn": 
                return <input className="TB-Input" style={ style } type="text" onChange={ event => this.handleChange(event.target.value.replace(/\D/g, "")) } value={this.props.field.answer ? (this.props.idx ? this.displaySSNFormat(this.props.field.answer.text) : this.displaySSNFormat(this.props.field.answer)) : ""} maxLength={this.props.field.max ? this.props.field.max : null} />
            case "zip": 
                return <input className="TB-Input" style={ style } type="text" onChange={ event => this.handleChange(event.target.value.replace(/\D/g, "")) } value={this.props.field.answer ? (this.props.idx ? this.displayZipFormat(this.props.field.answer.text) : this.displayZipFormat(this.props.field.answer)) : ""} maxLength={this.props.field.max ? this.props.field.max : null} />
            default:
                return <input className="TB-Input" style={ style } type="text" onChange={ event => this.handleChange(event.target.value) } value={this.props.field.answer ? (this.props.idx ? this.props.field.answer.text : this.props.field.answer) : ""} maxLength={this.props.field.max ? this.props.field.max : null}  />
        }
    }

    render() {
        let style = this.props.field.style ? this.props.field.style : null
        return (
            <div className="TB-Container">
                <span className="TB-Label" style={ style }>
                    { this.props.field.label }
                </span>
                { this.getTB() }
            </div>
        )
    }
}

export default FieldTB