import React, { Component } from 'react'
import './fields.css'

class FieldCBGRB extends Component {
    handleChange(val) {
        if(typeof(this.props.idx) === "undefined") {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val)
        } else {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val, this.props.idx)
        }
        
    }

    getOptions() {
        return (
            this.props.field.options.map((o, idx) => {
                return (
                    <div key={`cbg-${this.props.field.id}-${idx}`} className="CB-PDF-Container">
                        <input name={ o.val } className="CB-PDF-Input" type="checkbox" onChange={ () => this.handleChange(o) } checked={(this.props.field.answer && this.props.field.answer.val === o.val) || false} />
                        <span className="CB-PDF-Label">
                            {o.label}
                        </span>
                    </div>
                )
            })
        )
    }

    render() {
        return (
            <div className="CBG-Container">
                { this.props.field.label ? <div className="CB-PDF-Main-Label">{ this.props.field.label }</div> : null }
                { this.getOptions() }
            </div>
        )
    }
}

export default FieldCBGRB