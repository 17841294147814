import React, { Component } from 'react'
import PrettyCB from './prettyCb'
import './fields.css'

class FieldCBG extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(field, checked, idx) {
        console.log('CBG     ', field, checked, idx)
        this.props.onValueChange(this.props.field, checked, idx)
    }

    getOptions() {
        return (
            this.props.field.options.map((o, idx) => {
                return (
                    <PrettyCB key={`pcb-${idx}`} field={ o } onValueChange={ this.handleChange } idx={idx} />
                )
            })
        )
    }

    render() {
        let style = this.props.field.style ? this.props.field.style : null
        style = { ...style, color: this.props.field.required && !this.props.field.options.filter(o => o.answer).length > 0 ?  "#a94442" : (this.props.field.required && this.props.field.options.filter(o => o.answer) ? "#3c763d" : null) }
        return (
            <div>
                {this.props.field.label ? <div className="Pretty-CBG-Label" style={style}>{this.props.field.label}</div> : null}
                {this.getOptions()}
            </div>
        )
    }
}

export default FieldCBG