//-------- Helper Components -------
import axios from 'axios'
import moment from 'moment'

//-------- Variables & Constants ---
const TextURL = 'https://great-silica.glitch.me/textresponse'


//-------- Functions -----------
export function sendText(phone, msg) {
    const request = axios.post(`${TextURL}`, { phonenumber: phone, message:msg })
    return request
}

export function displayDateFormat(date) {
    let newDate = date.substring(0,2) 
    if(date.length > 2) newDate += "/" + date.substring(2,4)
    if(date.length > 4) newDate += "/" + date.substring(4)
    return newDate
}

export function displayPhoneFormat(phone) {
    let newPhone = "(" + phone.substring(0, 3)
    if (phone.length > 3) newPhone += ") " + phone.substring(3, 6)
    if (phone.length > 6) newPhone += "-" + phone.substring(6)
    return newPhone
}

export function getAlerts(mbr, w, bp, w5) {
    return {
        all: {
            color: mbr.bpAlert || mbr.w1dAlert || mbr.w5dAlert ? '#ef5350' : (!w || !bp || moment(w.ts).format('l') !== moment(new Date()).format('l') || moment(bp.ts).format('l') !== moment(new Date()).format('l') || !w5 ? '#ffd600' : '#66bb6a')
        },
        w1d: {
            color: mbr.w1dAlert ? '#ef5350' : (!w || moment(w.ts).format('l') !== moment(new Date()).format('l') ? '#ffd600' : '#66bb6a'),
            good: !mbr.w1dAlert,// && w && moment(w.ts).format('l') === moment(new Date()).format('l'),
            from: mbr.w1dFrom || null,
            to: mbr.w1dTo || null,
            amount: mbr.w1dTo && mbr.w1dFrom ? `${parseInt(mbr.w1dTo - mbr.w1dFrom)} lbs` : null,
            date: mbr.w1dDate ? moment(mbr.w1dDate).format('l') : null
        },
        w5d: {
            color: mbr.w5dAlert ? '#ef5350' : (!w || moment(w.ts).format('l') !== moment(new Date()).format('l') || !w5 ? '#ffd600' : '#66bb6a'),
            good: !mbr.w5dAlert,// && w && moment(w.ts).format('l') === moment(new Date()).format('l'),
            from: mbr.w5dFrom || null,
            to: mbr.w5dTo || null,
            amount: mbr.w5dTo && mbr.w5dFrom ? `${parseInt(mbr.w5dTo - mbr.w5dFrom)} lbs` : null,
            date: mbr.w5dDate ? moment(mbr.w5dDate).format('l') : null
        },
        bp: {
            color: mbr.bpAlert ? '#ef5350' : (!bp || moment(bp.ts).format('l') !== moment(new Date()).format('l') ? '#ffd600' : '#66bb6a'),
            good: !mbr.bpAlert,// && bp && moment(bp.ts).format('l') === moment(new Date()).format('l'),
            amount: mbr.bpAlert && mbr.bpAmount ? mbr.bpAmount : null,
            date: mbr.bpDate ? moment(mbr.bpDate).format('l') : null
        }
    }
}


