export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "rpm-reg"
      },
      apiGateway: {
        REGION: "us-east-2",
        URL: "https://byew0msho7.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_pXfUQpkem',
        APP_CLIENT_ID: '15dlfgbdd554hn36rjk6ja82ks',
        IDENTITY_POOL_ID: 'us-east-2:4ed63bf9-6076-4f90-baf6-627bc2421faf'
    }
}
