import React, { Component, Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import { Nav, Navbar, NavItem } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import Routes from './routes'
import { Auth, API } from 'aws-amplify'
import Logo from './images/gw-no-bg.png'
import HeartBeat from './images/monitor-heart-rate.svg'
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      view: 'cards'
    }
  }

  async componentDidMount() {
    try {
      await Auth.currentSession()
      let currUser = await Auth.currentAuthenticatedUser()
      let user = await API.get('rpm', `/user/${currUser.username}`)
      this.setUser(user)
      this.userHasAuthenticated(true)
      this.setState({ loadingCss: 'App-Loading-Bar-Final' })
    } catch (e) {
      if (e !== 'No Current User') console.log('error    ', e.message)
    }

    setTimeout(() => this.setState({ loadingCss: 'App-Loading-Bar-Post' }), 230)
    setTimeout(() => this.setState({ isAuthenticating: false, loadedCss: 'App-Showing' }), 500)
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setUser = user => { this.setState({ user }) }
  setView = view => { this.setState({ view }) }


  handleLogout = async event => {
    try {
      await Auth.signOut({ global: true })
      //return false
    } catch (e) {
      console.log(e.message)
    }
    this.userHasAuthenticated(false)
    this.props.history.push('/login')
  }

  render() {
    //console.log('app    ', this.state, this.props)
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUser: this.setUser,
      user: this.state.user,
      setView: this.setView,
      view: this.state.view
    }

    return (
      !this.state.isAuthenticating ?
        <div className={`App App-Container`}>
          <Navbar fluid collapseOnSelect style={{ background: 'linear-gradient(to right, #3771be 0%, #41c2e9 100%)', marginBottom: '0px' }}>
            <Navbar.Header>
              <Navbar.Brand style={{display: 'inline-block'}}>
                <Link to="/">{this.state.user ? <img src={HeartBeat} alt="" style={{height: '38px', float: 'left', marginTop: '-10px'}} /> : null}<img src={Logo} style={{ height: '38px', marginTop: '-10px', float: 'left' }} alt='GuideWell' /></Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                {this.state.isAuthenticated ?
                  <Fragment>
                    <Navbar.Text><span style={{ color: '#fff' }}>{this.state.user && this.state.user[0] && `Welcome Back, ${this.state.user[0].firstName}`}</span></Navbar.Text>
                    <LinkContainer to="/register">
                      <NavItem>Register Devices</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/dashboard">
                      <NavItem>View Dashboard</NavItem>
                    </LinkContainer>
                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment> :
                  <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
        </div> :
        <div className="App-Loading">
          <div className={`App-Loading-Bar ${this.state.loadingCss}`}></div>
        </div>
    )
  }
}

export default withRouter(App)