import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './fields.css'

class PrettySign extends Component {

    handleSign() {
        this.props.onValueChange(this.props.field, true)
    }

    render() {
        return (
            <div>
                {
                    this.props.field.type === 'signature' ?
                        <div className="Sign-Container">
                            <div className="Pretty-Sign-Label" style={{ fontWeight: "bold", color: this.props.field.required && !this.props.field.answer ?  "#a94442" : (this.props.field.required && this.props.field.answer ? "#3c763d" : null) }} onClick={() => this.handleSign() }>
                                {this.props.field.label || "Signature: "}
                            </div>
                            <div className="Pretty-Sign-Value" onClick={() => this.handleSign() }>
                                {this.props.field.answer ? <img src={this.props.field.answer} alt="Signature" /> : null}
                                <div className="Pretty-Sign-Info">Touch to apply Signature</div>
                            </div>
                        </div> :
                        <div className="Pretty-Sign-Container">
                            <div className="Pretty-Initial-Value" onClick={() => this.handleSign() }>
                                {this.props.field.answer ? <img src={this.props.field.answer} alt="Initial" /> : null}
                                <div className="Pretty-Sign-Info">Touch to add Initial</div>
                            </div>
                            { this.props.field.subtype === "html" ? 
                                ReactHtmlParser(this.props.field.label) :
                                <div className="Pretty-Sign-Label" style={{ fontWeight: "bold", color: this.props.field.required && !this.props.field.answer ?  "#a94442" : (this.props.field.required && this.props.field.answer ? "#3c763d" : null) }} onClick={() => this.handleSign() }>
                                    {this.props.field.label || "Initial "}
                                </div>
                            }
                        </div>
                }
            </div>
        )
    }
}

export default PrettySign