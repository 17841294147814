import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './fields.css'

class FieldSign extends Component {

    handleSign() {
        this.props.onValueChange(this.props.field, true)
    }

    render() {
        return (
            <div>
                {
                    this.props.field.type === 'signature' ?
                        <div className="Sign-Container">
                            <div className="Sign-Label" onClick={() => this.handleSign() }>
                                {this.props.field.label || "Signature: "}
                            </div>
                            <div className="Sign-Value" onClick={() => this.handleSign() }>
                                {this.props.field.answer ? <img src={this.props.field.answer} alt="Signature" /> : null}
                            </div>
                        </div> :
                        <div className="Sign-Container">
                            <div className="Initial-Value" onClick={() => this.handleSign() }>
                                {this.props.field.answer ? <img src={this.props.field.answer} alt="Initial" /> : null}
                            </div>
                            { this.props.field.subtype === "html" ? 
                                ReactHtmlParser(this.props.field.label) :
                                <div className="Sign-Label" onClick={() => this.handleSign() }>
                                    {this.props.field.label || "Initial "}
                                </div>
                            }
                        </div>
                }
            </div>
        )
    }
}

export default FieldSign