import React, { Component } from 'react'
import FieldElem from '../fields/fieldElem'
import FieldTB from '../fields/fieldTb'
import FieldTbl from '../fields/fieldTbl'
import FieldText from '../fields/fieldText'
import FieldCB from '../fields/fieldCb'
import FieldCBG from '../fields/fieldCbg'
import FieldCBGRB from '../fields/fieldCbgrb'
import FieldMulti from '../fields/fieldMulti'
import FieldHTML from '../fields/fieldHtml'
import FieldSign from '../fields/fieldSign'
import SignatureCanvas from 'react-signature-canvas'
import PrettySignPreview from '../utilities/prettySignPreview'
import './form.css'
import '../utilities/signature.css'

class PDFForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sign: null,
            showSignature: false,
            css: '',
            scss: 'Pretty-Signature-Start'
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this)
        this.handleSignChanged = this.handleSignChanged.bind(this)
    }

    handleButton(button) {
        this._div.scrollTop = 0
        let good = true
        if(button === "next") {
            //this.props.form.forEach(f => {if(f.required && !f.answer) good = false})
        }
        this.props.sectionButtonClicked(button)
        console.log("Good = " + good)
    }

    handleClick() {
        const formToPrint = document.getElementById('pdf-form')
        this.props.onToPDF(formToPrint)
    }

    handleValueChange(field, val, idx, cell) {
        if(field.type === 'signature' || field.type === 'initial') {
            if (val) this.props.onFormChange({ ...field, answer: this.state.sign })
        } else if (field.type === 'table') {
            let newVal = Object.assign(field.rows)
            newVal[idx].cols[cell].answer = val
            this.props.onFormChange({ ...field, rows: newVal })
        } else if (field.type === 'cbg') {
            let newVal = Object.assign(field.options)
            newVal[idx].answer = val
            this.props.onFormChange({ ...field, options: newVal })
        } else if (typeof (idx) !== "undefined") {
            let newVal = Object.assign(field.answer || [])
            newVal[idx] = val
            this.props.onFormChange({ ...field, answer: newVal })
        } else {
            this.props.onFormChange({ ...field, answer: val })
        }
    }

    handleSignChanged(sign) {
        this.setState({ sign })
    }

    handleStartSignature() {
        this.setState({ css: ' Signature-Active'})
    }

    handleSignature() {
        this.setState({ sign: this.sigPad.getTrimmedCanvas().toDataURL('image/png'), css: '' })
    }

    handleSignatureDone() {
        this.setState({ scss: 'Pretty-Signature-Start' })
        setTimeout(() => this.setState({ showSignature: false }), 250)
    }

    handleNewSignature() {
        if(this.sigPad) this.sigPad.clear()
        this.setState({ sign: null, showSignature: true })
        setTimeout(() => this.setState({ scss: 'Pretty-Signature-End' }), 50)
    }

    getFormRow(form) {
        return (
            form.map((f, idx) => {
                switch (f.type) {
                    case 'cb':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldCB field={f} onValueChange={this.handleValueChange} /></div>
                    case 'cbg':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldCBG field={f} onValueChange={this.handleValueChange} /></div>
                    case 'cbgrb':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldCBGRB field={f} onValueChange={this.handleValueChange} /></div>
                    case "html":
                        return <div key={`f-${idx}`} className="Row-Item"><FieldHTML field={f} /></div>
                    case 'initial':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldSign field={f} onValueChange={this.handleValueChange} /></div>
                    case 'multi':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldMulti field={f} onValueChange={this.handleValueChange} /></div>
                    case 'rb':
                        return <div key={`f-${idx}`} className="Row-Item">{f.type}</div>
                    case 'signature':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldSign field={f} onValueChange={this.handleValueChange} /></div>
                    case 'table':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldTbl field={f} onValueChange={this.handleValueChange} /></div>
                    case 'tb':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldTB field={f} onValueChange={this.handleValueChange} /></div>
                    case 'text':
                        return <div key={`f-${idx}`} className="Row-Item"><FieldText field={f} /></div>
                    default:
                        return null
                }
            }))
    }

    getForm() {
        let row = 0
        return (
            this.props.form.map((f, idx) => {
                if (f.row && f.row !== row) {
                    row = f.row
                    return (
                        <div key={`row-${row}`} className="Row-Container">
                            {this.getFormRow(this.props.form.filter(r => r.row && r.row === row))}
                        </div>
                    )
                } else if (!f.row) {
                    switch (f.type) {
                        case "element":
                            return <div className="Row-Container" key={`f-${idx}`}><FieldElem field={f} /></div>
                        case "html":
                            return <div className="Row-Container" key={`f-${idx}`}><FieldHTML field={f} /></div>
                        case "text":
                            return <div className="Row-Container" key={`f-${idx}`}><FieldText field={f} /></div>
                        default:
                            return null
                    }
                } else return null
            }))
    }

    render() {
        return (
            <div ref={(ref) => this._div = ref} className="Outter-Container">
                <div id="pdf-form" className="Form-Container">
                    {this.getForm()}
                </div>
                <div className="Pretty-Footer">
                    <div className="Pretty-Button Pretty-Back Pretty-Left" onClick={() => this.handleButton('back')}>Back</div>
                    <div className="Pretty-Button Pretty-Quit Pretty-Left" onClick={() => this.handleButton('quit')}>Quit</div>
                    <div className="Pretty-Button Pretty-Quit Pretty-Left" onClick={() => this.handleButton('pretty')}>Easy View</div>
                    <div className="Pretty-Button Pretty-Next Pretty-Right" onClick={() => this.handleButton('next')}>Next</div>
                    <div className="Pretty-Button Pretty-Next Pretty-Right" onClick={() => this.handleClick()}>Submit</div>
                    <PrettySignPreview signature={ this.state.sign } msg={this.state.sign ? 'Touch to Reset' : ''} onSign={() => this.handleNewSignature()} />
                </div>
                {
                    this.state.showSignature ? 
                        <div className={`Pretty-Signature ${this.state.scss}`}>
                            <SignatureCanvas penColor={`#000`}
                                canvasProps={{width: 800, height: 350, className: `Signature${this.state.css}`}} 
                                ref={ref => { this.sigPad = ref }} onBegin={ () => this.handleStartSignature() } onEnd={ () => this.handleSignature() } />
                                <div className="Pretty-Signature-Button" onClick={() => this.handleSignatureDone() }>Accept Signature</div>
                        </div>: null
                }
            </div>
        )
    }
}

export default PDFForm