import React, { Component } from 'react'
import PrettyTB from '../fields/prettyTb'
import PrettyCBGRB from './prettyCbgrb'
import { ControlLabel, FormGroup, InputGroup } from 'react-bootstrap'
import './fields.css'

class FieldCB extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(field, val, idx) {
        this.props.onValueChange(this.props.field, val, idx)
    }

    getValidation() {
        return "warning"
    }

    render() {
        return (
            <FormGroup controlId={`mulit-${this.props.field.id}`} bsSize="large" validationState={ this.props.field.validation ? this.getValidation() : null } >
                { this.props.field.label ? <div className="Multi-PDF-Item" ><ControlLabel>{this.props.field.label}</ControlLabel></div> : null }
                <InputGroup>
                    {
                        this.props.field.options.map((t, tidx) => {
                            switch (t.type) {
                                case "cbgrb":
                                    return <div className="Multi-PDF-Item" key={`multi-${this.props.field.id}-${tidx}`}><PrettyCBGRB field={{...t, answer: this.props.field.answer && this.props.field.answer[tidx] ? this.props.field.answer[tidx] : null}} onValueChange={this.handleChange} idx={tidx} /></div>
                                case "tb":
                                    return <div className="Multi-PDF-Item" key={`multi-${this.props.field.id}-${tidx}`}><PrettyTB field={{...t, answer: this.props.field.answer && this.props.field.answer[tidx] ? this.props.field.answer[tidx] : null}} onValueChange={this.handleChange} idx={tidx} /></div>
                                default:
                                    return null
                            }
                        })
                    }
                </InputGroup>
            </FormGroup>
        )
    }
}

export default FieldCB