import React, { Component } from 'react'
import { DropdownButton, MenuItem, InputGroup } from 'react-bootstrap'
import './fields.css'

class PrettyCBGRB extends Component {
    handleChange(val) {
        if(typeof(this.props.idx) === "undefined") {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val)
        } else {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val, this.props.idx)
        }
    }

    getOptions() {
        return (
            this.props.field.options.map((o, idx) => {
                return (
                    <MenuItem key={`ddi-${idx}`} eventKey={ o }>
                        { o.label }
                    </MenuItem>
                )
            })
        )
    }

    render() {
        return (
            <InputGroup>
                <span style={{ fontWeight: "bold", color: this.props.field.required && !this.props.field.answer ?  "#a94442" : (this.props.field.required && this.props.field.answer ? "#3c763d" : null) }}>{ this.props.field.label }</span>&nbsp;
                <DropdownButton style={{ fontSize: 'inherit' }}
                    id={`ddb-${this.props.field.id}`} 
                    title={  this.props.field.answer ? this.props.field.answer.label : "Select..." } 
                    onSelect={event => this.handleChange(event)}>
                    { this.getOptions() }
                </DropdownButton>
            </InputGroup>
        )
    }
}

export default PrettyCBGRB