import React, { Component } from 'react'
import './fields.css'

class FieldElem extends Component {

    getField() {
        if(!this.props.field || !this.props.field.type || !this.props.field.subtype || this.props.field.type !== 'element') return null
        switch(this.props.field.subtype) {
            case "hr":
                return <hr />
            default:
                return null
        }
    }

    render() {
        return (
            <div className="Element-Container">{ this.getField() }</div>
        )
    }
}

export default FieldElem