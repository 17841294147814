import React, { Component } from 'react'
import './fields.css'

class PrettyCB extends Component {
    handleChange(val) {
        this.props.onValueChange(this.props.field, val.checked, typeof(this.props.idx) === "undefined" ? null : this.props.idx)
    }

    render() {
        return (
            <div>
                <label className="CB-Container">
                    { this.props.field.label }
                    <input name={this.props.field.val} type="checkbox" onChange={ event => this.handleChange(event.target) } checked={this.props.field.answer || false} />
                    <span className="CB-Checkmark"></span>
                </label>

            </div>

        )
    }
}

export default PrettyCB